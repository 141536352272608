import { render, staticRenderFns } from "./debt-management-vs-debt-settlement-–-what’s-the-difference.md?vue&type=template&id=115e6853&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports